<template>
  <div class="signup-paragraphs">
    <p class="have-acc-agree text-center">
      By signing up, you confirm you're <strong>over the age of 13</strong> & agree to the
      <strong
        ><router-link to="/terms">Terms of Service </router-link> and
        <router-link to="/privacy"> Privacy Policy.</router-link></strong
      >
    </p>
    <p class="have-acc loginer text-center">Have an account? <router-link to="/login"> Log in </router-link></p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';

@Options({
  name: 'SignupParagraphs',
  components: {},
})
export default class SignupParagraphs extends Vue {
  public navigateTo(page: string) {
    const router = useRouter();
    router.push(page);
  }
}
</script>

<style lang="sass" scoped>
.signup-paragraphs
  text-align: center
.loginer
  font-weight: bold !important
</style>
